import Segment from '../Segment';

declare const is_node: boolean;
declare const analytics: any;
type AffiliateId = string | null;
type DeviceType = 'mobile' | 'tablet' | 'desktop';

class Affiliate {
    private affiliateId: AffiliateId;
    private redirectablePartners: string[];
    private deviceType: DeviceType | null;

    constructor() {
        this.affiliateId = null;
        this.deviceType = null;
        this.getIdFromUrl();

        this.redirectablePartners = [
            'moodytest',
            'kateandtoms',
            'hostandstay_PM',
        ];
    }

    check(): boolean {
        return this.getAffiliateId() !== null;
    }

    getAffiliateId(): AffiliateId {
        return this.affiliateId;
    }

    setAffiliateId(affiliateId: AffiliateId): void {
        this.affiliateId = affiliateId;
    }

    getIdFromUrl(): AffiliateId {
        if (is_node) return null;

        try {
            const url: URL = new URL(window.location.href);
            const affiliateId: AffiliateId = url.searchParams.get('a_aid');

            if (!affiliateId) return null;

            this.setAffiliateId(affiliateId);
            return affiliateId;
        } catch (error) {
            console.error('Error getting affiliate ID from URL', error);
            return null;
        }
    }

    findDeviceType(): DeviceType {
        if (navigator?.userAgent) {
            return navigator.userAgent.includes('Mobile')
                ? 'mobile'
                : navigator.userAgent.includes('Tablet')
                    ? 'tablet'
                    : 'desktop';
        }

        if (window?.innerWidth < 768) {
            return 'mobile';
        }

        if (window?.innerWidth < 1024) {
            return 'tablet';
        }

        return 'desktop';
    }

    getDeviceType(): DeviceType {
        if (this.deviceType) return this.deviceType;

        this.deviceType = this.findDeviceType();
        return this.deviceType;
    }

    // Yb        dP 88  88    db    888888 .dP"Y8    db    88""Yb 88""Yb
    //  Yb  db  dP  88  88   dPYb     88   `Ybo."   dPYb   88__dP 88__dP
    //   YbdPYbdP   888888  dP__Yb    88   o.`Y8b  dP__Yb  88"""  88"""
    //    YP  YP    88  88 dP""""Yb   88   8bodP' dP""""Yb 88     88

    isRedirectablePartner(): boolean {
        return (
            this.check() &&
            this.redirectablePartners.includes(this.getAffiliateId())
        );
    }

    getMessageReference(): string {
        const device: string =
            {
                mobile: 'm',
                tablet: 't',
                desktop: 'd',
            }[this.getDeviceType()] ?? '';

        return `${this.getAffiliateId()}-${device}`;
    }

    getWhatsAppRedirectUrl(): string {
        const number: string = '447388528200';
        const messageReference: string = this.getMessageReference();
        const copy: string = `Hey, Yhangry Food Concierge!\n\nWhat food options can you offer me for my stay?\n\n--\nref #${messageReference}`;
        const encodedCopy: string = encodeURIComponent(copy);

        return `https://wa.me/${number}?text=${encodedCopy}`;
    }

    async redirectToWhatsapp(): Promise<void> {
        if (!this.isRedirectablePartner() || this.getDeviceType() === 'desktop') return;

        const redirectUrl: string = this.getWhatsAppRedirectUrl();
        const data: Record<string, string> = {
            to: redirectUrl,
            partnerReference: this.getAffiliateId(),
            deviceType: this.getDeviceType(),
        };

        // TODO: why this promise is taking so long on prod?
        // await Segment.track('affiliate.redirect', data);

        await analytics.track('affiliate.redirect', data);

        // wait for analytics to run
        await new Promise((resolve) => setTimeout(resolve, 500));

        window.location.href = redirectUrl;
    }

    // 88  88  dP"Yb  88     88 8888b.     db    Yb  dP .dP"Y8
    // 88  88 dP   Yb 88     88  8I  Yb   dPYb    YbdP  `Ybo."
    // 888888 Yb   dP 88  .o 88  8I  dY  dP__Yb    8P   o.`Y8b
    // 88  88  YbodP  88ood8 88 8888Y"  dP""""Yb  dP    8bodP'

    getHolidayRedirectUrl(): string {
        return `https://holiday.yhangry.com/?a_aid=${this.getAffiliateId()}`;
    }

    async holidayRedirect(): Promise<void> {

        // check for affiliate id
        if (!this.check()) return;

        try {
            // check for holiday redirect
            const redirectUrl: string = this.getHolidayRedirectUrl();
            const data: Record<string, string> = {
                to: redirectUrl,
                partnerReference: this.getAffiliateId(),
                deviceType: this.getDeviceType(),
            };

            // put analytics in a try catch block to avoid breaking the app if an ad blocker is blocking the request
            try {
                await analytics.track('affiliate.redirect', data);
            } catch (error) {
                console.error('Error tracking redirect in PostHog', error);
            }

            // wait for analytics to run outside of try catch block
            //      this will allow other libraries like (PAP) to run before redirecting
            await new Promise((resolve) => setTimeout(resolve, 500));

            window.location.href = redirectUrl;

        } catch (error) {
            console.error('Error redirecting to holiday url', error);
        }
    }
}

export default Affiliate;
